import { Controller } from "stimulus"
import { hideTarget, showTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["message", "html"]

  connect() {
    if(this.hasMessageTarget) {
      hideTarget(this.messageTarget)
    }
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  copy(event) {
    event.preventDefault()

    if(event.target.hasAttribute("data-text")) {
      const copyText = event.target.getAttribute("data-text")
      navigator.clipboard.writeText(copyText)
    }
    else if(this.hasHtmlTarget) {
      const html = this.htmlTarget.outerHTML
      navigator.clipboard.writeText(html)
    }

    if(this.hasMessageTarget) {
      showTarget(this.messageTarget)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        hideTarget(this.messageTarget)
      }, 3000)
    }
  }
}

