import { Controller } from "stimulus"
import { hideTarget, showTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["dropdown", "start", "end"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const value = this.dropdownTarget.value

    if(value === "between" || value === "not_between") {
      showTarget(this.startTarget)
      showTarget(this.endTarget)
    }
    else if(value === "greater_than" || value === "greater_than_or_equal" || value == "equal" || value == "not_equal") {
      showTarget(this.startTarget)
      hideTarget(this.endTarget)
    }
    else if(value === "less_than" || value === "less_than_or_equal") {
      hideTarget(this.startTarget)
      showTarget(this.endTarget)
    }
    else {
      hideTarget(this.startTarget)
      hideTarget(this.endTarget)
    }
  }
}
