export default function changeWithSuccessStatus({ change }) {
  return {
    ...change,
    newCell: {
      ...change.newCell,
      error: null,
      success: true,
    }
  }
}
