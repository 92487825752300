import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["checkbox", "orderIdField", "bulkUpdateControls", "selectAll"]

  connect() {
    this.update()
  }

  update() {
    if(this.hasOrderIdFieldTarget) {
      const checked = this.checkboxTargets.filter(c => c.checked)
      const orderIds = checked.map(checkbox => checkbox.value)
      this.orderIdFieldTargets.forEach(target => {
        target.value = orderIds.join(",")
      })

      if(orderIds.length) {
        showTarget(this.bulkUpdateControlsTarget)
      }
      else {
        hideTarget(this.bulkUpdateControlsTarget)
      }
    }
    else {
      this.hideAllCheckboxes()
    }
  }

  hideAllCheckboxes() {
    hideTarget(this.checkboxTargets)
    hideTarget(this.selectAllTargets)
  }

  toggleAll(e) {
    const checked = e.target.checked
    this.checkboxTargets
      .filter(checkbox => checkbox.checked !== checked)
      .forEach(checkbox => checkbox.click())
  }
}

