import constants from '../constants'
import extractCellFromData from './extractCellFromData'

function extractRowFromData({ data, rowIndex }) {
  const rowId = data.rows[rowIndex].rowId
  const height = constants.defaultRowHeight
  const cells = data.rows[rowIndex].cells.map((cell, cellIndex) => extractCellFromData({ data, rowIndex, cellIndex }))

  return { rowId, height, cells }
}

export default extractRowFromData
