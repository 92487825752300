import React, { useState } from "react";
import { ReactGrid, Column, Row, TextCellTemplate } from "@silevis/reactgrid";
import Paginator from "./Paginator";
import PerPageSelector from "./PerPageSelector";
import FlagCellTemplate from './FlagCellTemplate';
import ImageCellTemplate from './ImageCellTemplate';
import extractRowsFromData from "../util/extractRowsFromData";
import extractColumnsFromData from "../util/extractColumnsFromData";
import extractHeaderRowFromData from "../util/extractHeaderRowFromData";
import useWindowLocation from '../util/useWindowLocation'
import "@silevis/reactgrid/styles.css";

const Editor = ({ data, handleCellChanges, handleColumnsReorder, handleColumnWidthChange, className }) => {
  const columns = extractColumnsFromData(data)
  const rows = extractRowsFromData(data)
  const headerRow = extractHeaderRowFromData(data)
  const [location, setLocation] = useWindowLocation()

  function handlePageChange(page) {
    const newUrl = new URL(location)
    newUrl.searchParams.set("page", page)
    setLocation(newUrl.toString())
    window.scrollTo(0, 0)
  }

  function handlePerPageChange(perPage) {
    const newUrl = new URL(location)
    newUrl.searchParams.set("per", perPage)
    setLocation(newUrl.toString())
    window.scrollTo(0, 0)
  }

  return (
    <div className={className}>
      <ReactGrid
        columns={columns}
        rows={[
          headerRow,
          ...rows
        ]}
        onCellsChanged={handleCellChanges}
        onColumnsReordered={handleColumnsReorder}
        onColumnResized={handleColumnWidthChange}
        enableRangeSelection
        enableRowSelection={false}
        enableColumnSelection={true}
        stickyTopRows={1}
        stickyLeftColumns={1}
        customCellTemplates={{
          flag: new FlagCellTemplate(),
          image: new ImageCellTemplate(),
          // text: new CustomTextCellTemplate()
        }}
      />
      <div className="pt-3 d-flex justify-content-between">
        <Paginator pagination={data.pagination} handlePageChange={handlePageChange} />
        <PerPageSelector pagination={data.pagination} handlePerPageChange={handlePerPageChange} />
      </div>
    </div>
  )
}

export default Editor
