export default function serializedValueForChange(change) {
  const { newCell, previousCell } = change
  const { id, type, modelName, updateColumn, date, value, text, meta } = newCell

  console.log({ newCell })
  let submitValue
  switch(type) {
    case 'text':
      submitValue = text
      break
    case 'date':
      submitValue = date
      break
    case 'number':
      submitValue = text
      break
    default:
      console.log({ newCell })
      throw new Error(`Unknown type: ${type}`)
  }

  const submittedData = {
    [modelName]: {
      [updateColumn]: submitValue,
    }
  }
  // const submittedData = {
  //   development_product: {
  //     [updateColumn]: submitValue,
  //   }
  // }

  return submittedData
}
