import React from 'react'

const RefreshButton = ({ loading, onClick }) => (
  <>
    { loading ?
      <button className="btn btn-outline-secondary btn-sm me-2" type="button" disabled>
        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
        { " " }
        <span role="status">Loading...</span>
      </button>
      :
      <button className="btn btn-outline-secondary btn-sm me-2" type="button" onClick={onClick}>
        <i className="bi bi-arrow-clockwise"></i>
        Refresh
      </button>
    }
  </>
)

export default RefreshButton
