import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["preview", "file"]

  connect() {
    hideTarget(this.previewTarget)
    document.addEventListener('paste', this.handlePaste)
  }

  disconnect() {
    // Stop listening for paste
    document.removeEventListener('paste', this.handlePaste)
  }

  handlePaste = (event) => {
    const item = event.clipboardData.items[0]
    if (item.type.indexOf('image') === 0) {
      const blob = item.getAsFile()
      const reader = new FileReader()
      reader.onload = (event) => {
        this.previewTarget.src = event.target.result
        this.fileTarget.value = event.target.result
        showTarget(this.previewTarget)
      }
      reader.readAsDataURL(blob)
    }
  }
}
