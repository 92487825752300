import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["form", "clone"]

  connect() {
    hideTarget(this.formTarget)
    hideTarget(this.cloneTarget)
  }

  showForm(e) {
    e.preventDefault()
    showTarget(this.formTarget)
    hideTarget(this.cloneTarget)
  }

  showClone(e) {
    e.preventDefault()
    showTarget(this.cloneTarget)
    hideTarget(this.formTarget)
  }
}
