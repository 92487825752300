import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "addCustomerChargebackButton",
    "addVendorChargebackButton",
    "applyCustomerChargeback",
    "applyVendorChargeback",
    "customerChargebackOptions",
    "vendorChargebackOptions",
    "customerChargeBackAmount",
    "vendorChargeBackAmount",
    "chargebackNotes",
    "total"
  ]

  connect() {
    this.refreshChargebackVisibility()
  }
  
  addCustomerChargeback(e) {
    e.preventDefault()

    this.applyCustomerChargebackTarget.value = true
    this.customerChargeBackAmountTarget.value = this.totalTarget.value
    this.refreshChargebackVisibility()
  }

  removeCustomerChargeback(e) {
    e.preventDefault()

    this.applyCustomerChargebackTarget.value = false
    this.refreshChargebackVisibility()
  }

  addVendorChargeback(e) {
    e.preventDefault()

    this.applyVendorChargebackTarget.value = true
    this.vendorChargeBackAmountTarget.value = this.totalTarget.value
    this.refreshChargebackVisibility()
  }

  removeVendorChargeback(e) {
    e.preventDefault()

    this.applyVendorChargebackTarget.value = false
    this.refreshChargebackVisibility()
  }

  refreshChargebackVisibility() {
    const applyCustomerChargeback = (this.applyCustomerChargebackTarget.value == 'true')
    const applyVendorChargeback = (this.applyVendorChargebackTarget.value == 'true')

    if(applyCustomerChargeback) {
      hideTarget(this.addCustomerChargebackButtonTarget)
      showTarget(this.customerChargebackOptionsTarget)
    }
    else {
      showTarget(this.addCustomerChargebackButtonTarget)
      hideTarget(this.customerChargebackOptionsTarget)
    }

    if(applyVendorChargeback) {
      hideTarget(this.addVendorChargebackButtonTarget)
      showTarget(this.vendorChargebackOptionsTargets)
    }
    else {
      showTarget(this.addVendorChargebackButtonTarget)
      hideTarget(this.vendorChargebackOptionsTargets)
    }

    if(applyCustomerChargeback || applyVendorChargeback) {
      showTarget(this.chargebackNotesTarget)
    }
    else {
      hideTarget(this.chargebackNotesTarget)
    }
  }
}
