import * as React from "react";
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  UncertainCompatible,
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid";
// import "./flag-cell-style.css";
//


class FlagCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text);
    return {...uncertainCell, text, value}
  }
// handleKeyDown(
//     cell: Compatible<FlagCell>,
//     keyCode: number,
//     ctrl: boolean,
//     shift: boolean,
//     alt: boolean
// ): { cell: Compatible<FlagCell>; enableEditMode: boolean } {
//     if (!ctrl && !alt && isAlphaNumericKey(keyCode))
//         return { cell, enableEditMode: true };
//     return {
//         cell,
//         enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
//     };
// }

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) {
      return { cell, enableEditMode: true };
    }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    };
  }
  // update(cell: Compatible<FlagCell>, cellToMerge: UncertainCompatible<FlagCell>): Compatible<FlagCell> {
  //     return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  // }
  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }
// render(
//     cell: Compatible<FlagCell>,
//     isInEditMode: boolean,
//     onCellChanged: (cell: Compatible<FlagCell>, commit: boolean) => void
// ): React.ReactNode {
//   if (!isInEditMode) {
//     const flagISO = cell.text.toLowerCase(); // ISO 3166-1, 2/3 letters
//     const flagURL = `https://restcountries.eu/data/${flagISO}.svg`;
//     const alternativeURL = `https://upload.wikimedia.org/wikipedia/commons/0/04/Nuvola_unknown_flag.svg`;
//     return (
//       <div
//         className="rg-flag-wrapper"
//         style={{ backgroundImage: `url(${flagURL}), url(${alternativeURL})` }}
//       />
//     );
//   }
//   return (
//     <input
//       ref={input => {
//         input && input.focus();
//       }}
//       defaultValue={cell.text}
//       onChange={e =>
//         onCellChanged(
//           this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
//           false
//         )
//       }
//       onCopy={e => e.stopPropagation()}
//       onCut={e => e.stopPropagation()}
//       onPaste={e => e.stopPropagation()}
//       onPointerDown={e => e.stopPropagation()}
//       onKeyDown={e => {
//       if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
//         e.stopPropagation();
//       }}
//     />
//   );
// }
  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      console.log({ cell })
      const flagISO = cell.text.toLowerCase();
      const flagURL = `https://restcountries.eu/data/${flagISO}.svg`;
      const alternativeURL = `https://upload.wikimedia.org/wikipedia/commons/0/04/Nuvola_unknown_flag.svg`;
      return (
        <div
          className="rg-flag-wrapper"
          style={{ border: "3px solid #ff0", width: "100%", height: "100%", backgroundImage: `url(${flagURL}), url(${alternativeURL})` }}
        />
      );
    }
    return (
      <input
        ref={input => {
          input && input.focus();
        }}
        defaultValue={cell.text}
        onChange={e =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false
          )
        }
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        onKeyDown={e => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) {
            e.stopPropagation();
          }
        }}
      />
    );
  }
}

export default FlagCellTemplate;
