import constants from '../constants'
import extractHeaderCellFromData from './extractHeaderCellFromData'

function extractHeaderRowFromData(data) {
  const height = constants.defaultRowHeight
  const cells = data.header.cells.map((cell, cellIndex) => extractHeaderCellFromData({ cell, cellIndex }))

  return {
    height,
    rowId: "header",
    cells
  }
}

export default extractHeaderRowFromData
