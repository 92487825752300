import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["checkbox", "chargebackIdField", "bulkUpdateControls"]

  connect() {
    this.update()
  }

  update() {
    const checked = this.checkboxTargets.filter(c => c.checked)
    const chargebackIds = checked.map(checkbox => checkbox.value)
    this.chargebackIdFieldTarget.value = chargebackIds.join(",")

    if(chargebackIds.length) {
      showTarget(this.bulkUpdateControlsTarget)
    }
    else {
      hideTarget(this.bulkUpdateControlsTarget)
    }
  }

  hideAllCheckboxes() {
    hideTarget(this.checkboxTargets)
  }
}

