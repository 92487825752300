import React, { useState } from "react"
import { Button, Modal, Form } from "react-bootstrap"
import useWindowLocation from '../util/useWindowLocation'

const SaveReport = ({ client }) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reportName, setReportName] = useState('')
  const [addToSidebar, setAddToSidebar] = useState(false)
  const [errors, setErrors] = useState({})
  const [location, setLocation] = useWindowLocation()

  const handleSaveReport = async (e) => {
    e.preventDefault()

    setLoading(true)

    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
    const data = {
      saved_report: {
        path: location,
        name: reportName,
        add_to_sidebar: addToSidebar,
      }
    }

    try {
      const response = await client.post(`/saved_reports/`, data, { headers })

      setShowModal(false)

      window.location.reload()
    }
    catch(error) {
      if(error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors)
      }
      else {
        alert(`Failed to save report ${reportName}`)
      }
    }

    setLoading(false)
  }

  return (
    <>
      <Button variant="outline-secondary" size="sm" onClick={() => setShowModal(true)}>
        <i className="bi bi-bookmark"></i>&nbsp; Save Report
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Form onSubmit={handleSaveReport}>
          <Modal.Header closeButton>
            <Modal.Title>Save Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="reportForm.ReportName">
              <Form.Label>Report Name *</Form.Label>
              <Form.Control
                type="text"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name} 
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="reportForm.ReportSidebar">
              <Form.Check
                type="checkbox"
                label="Add to sidebar?"
                checked={addToSidebar}
                onChange={(e) => setAddToSidebar(e.target.checked)}
                isInvalid={errors.add_to_sidebar}
              />
              <Form.Control.Feedback type="invalid">
                {errors.add_to_sidebar}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default SaveReport
