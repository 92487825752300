import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["wrapper"]

  connect() {
    this.recalculate()
  }

  recalculate() {
    this.totalCounter = {}

    this.wrapperTarget.querySelectorAll('.order-line').forEach(orderLineTarget => {
      const orderId = orderLineTarget.getAttribute("data-order-id")
      const orderItemId = orderLineTarget.getAttribute("data-order-item-id")
      
      const unitsOrdered = parseFloat(orderLineTarget.getAttribute("data-units-ordered"))
      const unitsOtherReceived = parseFloat(orderLineTarget.getAttribute("data-other-received"))
      let unitsCurrentReceived = parseFloat(orderLineTarget.querySelector('.units-received').value)
      if(isNaN(unitsCurrentReceived)) {
        unitsCurrentReceived = 0
      }
      const unitsTotalReceived = unitsOtherReceived + unitsCurrentReceived
      const unitVariance = -(unitsOrdered - unitsTotalReceived)

      if(!isNaN(unitsTotalReceived)) {
        orderLineTarget.querySelector('.line-total-received').innerText = unitsTotalReceived
      }
      if(!isNaN(unitVariance)) {
        orderLineTarget.querySelector('.line-unit-variance').innerText = unitVariance
      }
      this.addTotal(`.item-total-ordered-${orderItemId}`, unitsOrdered)
      this.addTotal(`.item-total-other-received-${orderItemId}`, unitsOtherReceived)
      this.addTotal(`.item-total-current-received-${orderItemId}`, unitsCurrentReceived)
      this.addTotal(`.item-total-total-received-${orderItemId}`, unitsTotalReceived)
      this.addTotal(`.item-total-difference-${orderItemId}`, unitVariance)

      this.addTotal(`.order-total-ordered-${orderId}`, unitsOrdered)
      this.addTotal(`.order-total-other-received-${orderId}`, unitsOtherReceived)
      this.addTotal(`.order-total-current-received-${orderId}`, unitsCurrentReceived)
      this.addTotal(`.order-total-total-received-${orderId}`, unitsTotalReceived)
      this.addTotal(`.order-total-difference-${orderId}`, unitVariance)

      this.addTotal(`.total-ordered`, unitsOrdered)
      this.addTotal(`.total-other-received`, unitsOtherReceived)
      this.addTotal(`.total-current-received`, unitsCurrentReceived)
      this.addTotal(`.total-total-received`, unitsTotalReceived)
      this.addTotal(`.total-difference`, unitVariance)

      // if(!isNaN(unitDifference)) {
      //   orderLineTarget.querySelector('.unit-difference').innerText = unitDifference
      // }
    })

    Object.keys(this.totalCounter).forEach(className => {
      const target = this.wrapperTarget.querySelector(className)
      if(target) {
        target.innerText = this.totalCounter[className]
      }
    })
  }

  fillAll(e) {
    e.preventDefault()


    e.target.closest('.order').querySelectorAll('.order-line').forEach(orderLineTarget => {
      const unitsOrdered = parseFloat(orderLineTarget.getAttribute("data-units-ordered"))
      const unitsOtherReceived = parseFloat(orderLineTarget.getAttribute("data-other-received"))

      const unitsOutstanding = unitsOrdered - unitsOtherReceived
      orderLineTarget.querySelector('.units-received').value = unitsOutstanding
    })
    this.recalculate()
  }

  addTotal(key, quantity) {
    let newQuantity = this.totalCounter[key] || 0
    if(!isNaN(quantity)) {
      newQuantity += quantity
    }
    this.totalCounter[key] = newQuantity
  }

  clearUnits(e) {
    e.preventDefault()
    const orderId = e.target.getAttribute("data-order-id")
    const orderItemId = e.target.getAttribute("data-order-item-id")

    let orderLines = Array.from(this.wrapperTarget.querySelectorAll('.order-line'))
    if(orderId) {
      orderLines = orderLines.filter(orderLineTarget => orderLineTarget.getAttribute("data-order-id") == orderId)
    }
    if(orderItemId) {
      orderLines = orderLines.filter(orderLineTarget => orderLineTarget.getAttribute("data-order-item-id") == orderItemId)
    }

    orderLines.forEach(orderLineTarget => {
      orderLineTarget.querySelector('.units-received').value = '0'
      this.recalculate()
    })
  }
}
