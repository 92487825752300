import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import useWindowLocation from '../util/useWindowLocation'
import useDebounce from '../util/useDebounce'

const SearchField = ({  }) => {
  const [location, setLocation] = useWindowLocation()
  const url = new URL(location)

  const [searchText, setSearchText] = useState(url.searchParams.get("search_text") || "")
  const [searchDelimiter, setSearchDelimiter] = useState(url.searchParams.get("search_delimiter") || ",")
  const debouncedSearchText = useDebounce(searchText, 500)


  useEffect(() => {
    const newUrl = new URL(location)
    newUrl.searchParams.set("search_text", debouncedSearchText)
    newUrl.searchParams.set("page", 1)
    setLocation(newUrl.toString())
  }, [debouncedSearchText])

  useEffect(() => {
    const newUrl = new URL(location)
    newUrl.searchParams.set("search_delimiter", searchDelimiter)
    newUrl.searchParams.set("page", 1)
    setLocation(newUrl.toString())
  }, [searchDelimiter])

  return (
    <div className="mb-3 row">
      <div className="col-10 p-0">
        <Form.Control
          type="text"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder="Search PO and style numbers..."
        />
      </div>
      <div className="col-2 p-0">
        <Form.Select
          aria-label="Delimiter"
          value={searchDelimiter}
          onChange={e => setSearchDelimiter(e.target.value)}
        >
          <option value=",">,</option>
          <option value=".">.</option>
          <option value="-">-</option>
          <option value=" ">Space</option>
        </Form.Select>
      </div>
    </div>
  )
}

export default SearchField
