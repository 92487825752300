import React from 'react'
import ReactDOM from 'react-dom'
import DevelopmentProductGanttChart from '../components/DevelopmentProductGanttChart'
import DevelopmentProductBulkEditor from '../components/DevelopmentProductBulkEditor'

const components = {
  "react-development-product-bulk-editor": DevelopmentProductBulkEditor,
  "react-development-product-gantt-chart": DevelopmentProductGanttChart
}

document.addEventListener('turbo:load', () => {
  Object.entries(components).forEach(entry =>  {
    const className = entry[0]
    const component = entry[1]
    const nodes = Array.from(document.getElementsByClassName(className))
    nodes.forEach(node => {
      const data = JSON.parse(node.getAttribute('data-props'))
      ReactDOM.render(React.createElement(component, data), node)
    })
  })
})
