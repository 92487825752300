import React from 'react'
import useWindowLocation from '../util/useWindowLocation'

const SortableHeaderCell = ({ cell, value, cellIndex }) => {
  const { sortBy, sortDirection } = cell
  const [location, setLocation] = useWindowLocation()

  const url = new URL(location)
  const currentSortDirection = url.searchParams.get("sort_direction")
  const currentSortBy = url.searchParams.get("sort")

  function setCurrent({ sortBy, sortDirection }) {
    const newUrl = new URL(location)
    newUrl.searchParams.set("sort", sortBy)
    newUrl.searchParams.set("sort_direction", sortDirection)
    setLocation(newUrl.toString())
  }

  function handleClick(e) {
    e.preventDefault()

    if(currentSortBy === sortBy) {
      setCurrent({ sortBy, sortDirection: currentSortDirection === "ASC" ? "DESC" : "ASC" })
    }
    else {
      setCurrent({ sortBy, sortDirection: "ASC" })
    }
  }

  return (
    <a href="#" onClick={handleClick} role="button" className="text-dark font-weight-bold">
      { currentSortBy === sortBy && (
        <span className="ms-1">
          <i className={`bi bi-sort-${currentSortDirection === "ASC" ? "up" : "down"}`}></i>
        </span>
      )}
      { value }
    </a>
  )
}

export default SortableHeaderCell
