import React from 'react'

const PerPageOption = ({ value, current, onClick }) => {
  function handleClick(e) {
    e.preventDefault()
    onClick(value)
  }

  if(value == current) {
    return (
      <span>{value}</span>
    )
  }
  else {
    return (
      <a href="#" onClick={handleClick}>{value}</a>
    )
  }
}

const PerPageSelector = ({ pagination, handlePerPageChange }) => {
  const { totalPages } = pagination
  if(totalPages <= 1) return null

  return (
    <div>
      Per Page:
      { " " }
      <PerPageOption value={10} current={pagination.perPage} onClick={handlePerPageChange} />
      { " " }
      |
      { " " }
      <PerPageOption value={25} current={pagination.perPage} onClick={handlePerPageChange} />
      { " " }
      |
      { " " }
      <PerPageOption value={50} current={pagination.perPage} onClick={handlePerPageChange} />
      { " " }
      |
      { " " }
      <PerPageOption value={100} current={pagination.perPage} onClick={handlePerPageChange} />
      { " " }
      |
      { " " }
      <PerPageOption value={250} current={pagination.perPage} onClick={handlePerPageChange} />
    </div>
  )
}

export default PerPageSelector
