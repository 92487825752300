function applyChangesToData(changes, prevData) {
  changes.forEach((change) => {
    const { rowId, columnId, newCell } = change
    const columnIndex = indexOfColumnId(prevData, columnId)
    prevData.rows[rowId].cells[columnIndex] = newCell
  });
  return { ...prevData };
}

function indexOfColumnId(data, columnId) {
  return data.columns.findIndex((column) => column.columnId === columnId)
}

export default applyChangesToData
