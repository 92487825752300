import { Controller } from "stimulus"
import { format, addDays, parseISO } from 'date-fns'
import currency from '../helpers/currency'
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "percent",
    "totalPercent",
  ]
  static values = {
    total: Number
  }

  connect() {
    this.recalculate()
  }

  recalculate() {
    let totalPercent = 0

    this.percentTargets.forEach(percentTarget => {
      const percent = parseFloat(percentTarget.value)


      if(!isNaN(percent)) {
        totalPercent += percent
      }
    })

    this.totalPercentTarget.innerText = `${totalPercent}%`
  }
}
