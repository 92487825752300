import { Controller } from "stimulus"
import currency from '../helpers/currency'

export default class extends Controller {
  static targets = [ "wrapper" ]

  connect() {
    this.recalculate()
    $(this.wrapperTarget).on('cocoon:after-insert', () => {
      this.recalculate()
    })
    $(this.wrapperTarget).on('cocoon:after-remove', () => {
      this.recalculate()
    })
  }

  disconnect() {
    $(this.wrapperTarget).off('cocoon:after-insert')
    $(this.wrapperTarget).off('cocoon:after-remove')
  }

  recalculate() {
    this.recalculateLines()
    this.recalculateAdjustments()
    this.recalculateCombinedTotal()
  }

  recalculateCombinedTotal() {
    const lineTotal = this.totalPriceCounter['.combined-total-price']
    if(lineTotal) {
      const adjustmentTotal = this.totalAdjustmentPrice
      const combinedTotal = lineTotal.add(adjustmentTotal)

      document.querySelectorAll('.invoice-controller-live-total').forEach(target =>  {
        target.innerText = combinedTotal.format()
      })
    }
  }

  recalculateLines() {
    this.totalCounter = {}
    this.totalPriceCounter = {}
    this.lineTotal = currency(0)

    this.wrapperTarget.querySelectorAll('.order-line').forEach(orderLineTarget => {
      const orderId = orderLineTarget.getAttribute("data-order-id")
      const orderItemId = orderLineTarget.getAttribute("data-order-item-id")
      
      const unitsOrdered = parseFloat(orderLineTarget.getAttribute("data-units-ordered"))
      const unitsReceived = parseFloat(orderLineTarget.getAttribute("data-units-received"))
      const unitsInvoiced = parseFloat(orderLineTarget.querySelector('.units-invoiced').value)
      const unitPrice = currency(orderLineTarget.querySelector('.unit-price').value)
      const lineTotalPrice = unitPrice.multiply(unitsInvoiced)

      this.addTotal(`.item-total-ordered-${orderItemId}`, unitsOrdered)
      this.addTotal(`.item-total-received-${orderItemId}`, unitsReceived)
      this.addTotal(`.item-total-invoiced-${orderItemId}`, unitsInvoiced)
      this.addTotalCurrency(`.item-total-price-${orderItemId}`, lineTotalPrice)

      this.addTotal(`.order-total-ordered-${orderId}`, unitsOrdered)
      this.addTotal(`.order-total-received-${orderId}`, unitsReceived)
      this.addTotal(`.order-total-invoiced-${orderId}`, unitsInvoiced)
      this.addTotalCurrency(`.order-total-price-${orderId}`, lineTotalPrice)

      this.addTotal(`.combined-total-ordered`, unitsOrdered)
      this.addTotal(`.combined-total-received`, unitsReceived)
      this.addTotal(`.combined-total-invoiced`, unitsInvoiced)
      this.addTotalCurrency(`.combined-total-price`, lineTotalPrice)

      this.lineTotal = this.lineTotal.add(lineTotalPrice)

      const lineTotalTarget = orderLineTarget.querySelector('.line-total')
      if(lineTotalTarget) {
        lineTotalTarget.innerText = lineTotalPrice.format()
      }
    })

    Object.keys(this.totalCounter).forEach(className => {
      const target = this.wrapperTarget.querySelector(className)
      if(target) {
        target.innerText = this.totalCounter[className]
      }
    })

    Object.keys(this.totalPriceCounter).forEach(className => {
      const target = this.wrapperTarget.querySelector(className)
      if(target) {
        target.innerText = this.totalPriceCounter[className].format()
        target.setAttribute("data-price", this.totalPriceCounter[className].value)
      }
    })

    this.wrapperTarget.querySelectorAll('.average-price').forEach(averagePriceTarget => {
      const totalPriceTarget = averagePriceTarget.parentNode.querySelector('.total-price')
      const totalPrice = currency(totalPriceTarget.getAttribute("data-price"))
      const quantity = parseFloat(averagePriceTarget.parentNode.querySelector('.total-quantity').innerText)

      let averagePrice = currency(0)
      if(quantity > 0) {
        averagePrice = totalPrice.divide(quantity)
      }

      averagePriceTarget.innerText = averagePrice.format()
    })
  }

  recalculateAdjustments() {
    let totalAdjustmentQuantity = 0
    this.totalAdjustmentPrice = currency(0)

    Array.from(this.wrapperTarget.querySelectorAll('.adjustment-line')).filter(l => visible(l)).forEach(adjustmentLineTarget => {
      const adjustmentQty = parseFloat(adjustmentLineTarget.querySelector('.adjustment-qty').value)
      const adjustmentPrice = currency(adjustmentLineTarget.querySelector('.adjustment-price').value)
      const adjustmentLineTotal = adjustmentPrice.multiply(adjustmentQty)

      adjustmentLineTarget.querySelector('.adjustment-line-total').innerText = adjustmentLineTotal.format()

      if(!isNaN(totalAdjustmentQuantity)) {
        totalAdjustmentQuantity += adjustmentQty
      }

      this.totalAdjustmentPrice = this.totalAdjustmentPrice.add(adjustmentLineTotal)
    })

    this.wrapperTarget.querySelector('.total-adjustment-quantity').innerText = totalAdjustmentQuantity
    this.wrapperTarget.querySelector('.total-adjustment-price').innerText = this.totalAdjustmentPrice.format()

    if(totalAdjustmentQuantity > 0) {
      const averageAdjustmentPrice = this.totalAdjustmentPrice.divide(totalAdjustmentQuantity)
      this.wrapperTarget.querySelector('.average-adjustment-price').innerText = averageAdjustmentPrice.format()
    }
    else {
      this.wrapperTarget.querySelector('.average-adjustment-price').innerText = ''
    }
  }

  addTotal(key, quantity) {
    let newQuantity = this.totalCounter[key] || 0
    if(!isNaN(quantity)) {
      newQuantity += quantity
    }
    this.totalCounter[key] = newQuantity
  }

  addTotalCurrency(key, price) {
    let currentPrice = this.totalPriceCounter[key] || currency(0)
    this.totalPriceCounter[key] = currentPrice.add(price)
  }

  clearUnits(e) {
    e.preventDefault()
    const orderId = e.target.getAttribute("data-order-id")
    const orderItemId = e.target.getAttribute("data-order-item-id")

    let orderLines = Array.from(this.wrapperTarget.querySelectorAll('.order-line'))
    if(orderId) {
      orderLines = orderLines.filter(orderLineTarget => orderLineTarget.getAttribute("data-order-id") == orderId)
    }
    if(orderItemId) {
      orderLines = orderLines.filter(orderLineTarget => orderLineTarget.getAttribute("data-order-item-id") == orderItemId)
    }

    orderLines.forEach(orderLineTarget => {
      orderLineTarget.querySelector('.units-invoiced').value = '0'
      this.recalculate()
    })

  }
}

function visible(line) {
  if(line.style.display == 'none') {
    return false
  }
  return true
}
