import { Controller } from "stimulus"
import currency from '../helpers/currency'

export default class extends Controller {
  static targets = ["wrapper", "defaultPrice", "defaultQuantity", "itemTotal"]

  connect() {
    this.recalculate()
  }

  updateAllPrices() {
    const value = this.defaultPriceTarget.value
    if(value) {
      this.wrapperTarget.querySelectorAll('.purchase-order-line input.price').forEach(target => target.value = value)
    }
    this.recalculate()
  }

  updateAllQuantities() {
    const value = this.defaultQuantityTarget.value
    this.wrapperTarget.querySelectorAll('.purchase-order-line input.quantity').forEach(target => target.value = value)
    this.recalculate()
  }

  recalculate() {
    let runningTotalCurrency = currency(0)

    this.wrapperTarget.querySelectorAll('.purchase-order-line').forEach(target => {
      const quantity = parseFloat(target.querySelector('input.quantity').value)
      const priceCurrency = currency(target.querySelector('input.price').value)

      const lineTotalTarget = target.querySelector('.line-total')

      if(isNaN(quantity) || isNaN(priceCurrency)) {
        lineTotalTarget.innerText = ""
      }
      else {
        const lineTotalCurrency = priceCurrency.multiply(quantity)
        lineTotalTarget.innerText = lineTotalCurrency.format()
        runningTotalCurrency = runningTotalCurrency.add(lineTotalCurrency)
      }
    })

    this.itemTotalTarget.innerText = runningTotalCurrency.format()

  }
}

