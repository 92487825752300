import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["to"]

  addEmail(e) {
    e.preventDefault()

    const email = e.target.getAttribute("data-email")

    let recipients = this.toTarget.value.split(/,/)
      .map(r => r.trim())
      .filter(r => r)
    recipients.push(email)

    this.toTarget.value = recipients.join(", ")
  }
}
