import HeaderCell from "../components/HeaderCell"

function extractHeaderCellFromData({ cell, cellIndex }) {
  return {
    ...cell,
    type: "text",
    nonEditable: true,
    // renderer: (value) => cell.sortBy ? NonSortableHeaderCell({ value, cell, cellIndex }) : NonSortableHeaderCell({ value, cell, cellIndex })
    renderer: (value) => HeaderCell({ value, cell, cellIndex })

  }
}

export default extractHeaderCellFromData
