import React from 'react'
import EditorContext from '../contexts/EditorContext'
import SortableHeaderCell from './SortableHeaderCell'
import NonSortableHeaderCell from './NonSortableHeaderCell'

const HeaderCell = ({ value, cell, cellIndex }) => {
  if(cell.sortBy) {
    return <SortableHeaderCell value={value} cell={cell} cellIndex={cellIndex} />
  }
  else {
    return <NonSortableHeaderCell value={value} cell={cell} cellIndex={cellIndex} />
  }
}

export default HeaderCell
