import * as React from "react";
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  UncertainCompatible,
  isNavigationKey,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from "@silevis/reactgrid";
// import "./flag-cell-style.css";
//


class ImageCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text);
    return {...uncertainCell, text, value}
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) {
      return { cell, enableEditMode: true };
    }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }

  render(cell, isInEditMode, onCellChanged) {
    return isInEditMode ? this.renderEdit(cell, onCellChanged) : this.renderView(cell);
  }

  renderEdit(cell, onCellChanged) {
    return (
      <input
        ref={input => {
          input && input.focus();
        }}
        defaultValue={cell.text}
        onChange={e =>
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              false
            )
        }
        onCopy={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onPaste={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        onKeyDown={e => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) {
            e.stopPropagation();
          }
        }}
      />
    );
  }

  renderView(cell) {
    const imageUrl = cell.text

    if(!imageUrl) return (<div></div>)

    const { className } = cell

    return (
      <div className={`image-cell ${className}`}>
        <img src={imageUrl} className="image-preview" />
      </div>
    );
  }
}

export default ImageCellTemplate;
