import { Controller } from "stimulus"
import currency from '../helpers/currency'

export default class extends Controller {
  static targets = [
    "customerItemTotal",
    "defaultCustomerPrice",
    "defaultQuantity",
    "defaultVendorPrice",
    "projectedFreight",
    "projectedFreightTotal",
    "vendorItemTotal",
    "unitTotal",
    "wrapper",
  ]

  connect() {
    this.recalculate()
  }

  updateAllCustomerPrices() {
    if(this.hasDefaultCustomerPriceTarget) {
      const value = this.defaultCustomerPriceTarget.value
      if(value) {
        this.wrapperTarget.querySelectorAll('.order-line input.customer-price').forEach(target => target.value = value)
      }
      this.recalculate()
    }
  }

  updateAllVendorPrices() {
    const value = this.defaultVendorPriceTarget.value
    if(value) {
      this.wrapperTarget.querySelectorAll('.order-line input.vendor-price').forEach(target => target.value = value)
    }
    this.recalculate()
  }

  updateAllQuantities() {
    const value = this.defaultQuantityTarget.value
    this.wrapperTarget.querySelectorAll('.order-line input.quantity').forEach(target => target.value = value)
    this.recalculate()
  }

  recalculate() {
    let runningCustomerTotalCurrency = currency(0)
    let runningVendorTotalCurrency = currency(0)
    let runningTotalQuantity = 0

    this.wrapperTarget.querySelectorAll('.order-line').forEach(target => {
      const quantity = parseFloat(target.querySelector('input.quantity').value)
      const customerPriceTarget = target.querySelector('input.customer-price')
      const customerPriceCurrency = customerPriceTarget ? currency(customerPriceTarget.value) : currency(0)
      const vendorPriceCurrency = currency(target.querySelector('input.vendor-price').value)

      const customerLineTotalTarget = target.querySelector('.customer-line-total')
      const vendorLineTotalTarget = target.querySelector('.vendor-line-total')

      if(!isNaN(quantity)) {
        runningTotalQuantity += quantity
      }

      if(isNaN(quantity) || isNaN(customerPriceCurrency)) {
        customerLineTotalTarget.innerText = ""
      }
      else {
        const customerLineTotalCurrency = customerPriceCurrency.multiply(quantity)
        if(customerLineTotalTarget) {
          customerLineTotalTarget.innerText = customerLineTotalCurrency.format()
        }
        runningCustomerTotalCurrency = runningCustomerTotalCurrency.add(customerLineTotalCurrency)
      }

      if(isNaN(quantity) || isNaN(vendorPriceCurrency)) {
        vendorLineTotalTarget.innerText = ""
      }
      else {
        const vendorLineTotalCurrency = vendorPriceCurrency.multiply(quantity)
        vendorLineTotalTarget.innerText = vendorLineTotalCurrency.format()
        runningVendorTotalCurrency = runningVendorTotalCurrency.add(vendorLineTotalCurrency)
      }
    })

    if(this.hasCustomerItemTotalTarget) {
      this.customerItemTotalTarget.innerText = runningCustomerTotalCurrency.format()
    }
    this.vendorItemTotalTarget.innerText = runningVendorTotalCurrency.format()
    this.unitTotalTarget.innerText = runningTotalQuantity

    const projectedFreightTotal = currency(this.projectedFreightTarget.value).multiply(runningTotalQuantity)
    this.projectedFreightTotalTarget.innerText = projectedFreightTotal.format()
  }
}

