import { Controller } from "stimulus"
import currency from '../helpers/currency'

export default class extends Controller {
  static targets = [ "source", "input", "multiplier", "output" ]

  connect() {
    this.inputTarget.value = this.sourceTarget.value
    this.multiplierTarget.value = 100
    this.recalculate()
  }

  recalculate() {
    const multiplierValue = parseFloat(this.multiplierTarget.value) / 100.0
    const inputValue = currency(this.inputTarget.value)

    if(!isNaN(multiplierValue) && !isNaN(inputValue)) {
      const result = inputValue.multiply(multiplierValue)
      this.outputTarget.value = result
    }
  }

  fillSource(event) {
    event.preventDefault()
    this.sourceTarget.value = this.outputTarget.value
  }
}
