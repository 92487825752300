var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.red = {
    style: { color: "red" },
    parser: function(element) {
        return element.style.color === "red"
    },
    inheritable: true
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  const { target } = event
  const unparsedTemplates = target.getAttribute("data-templates")
  if(!unparsedTemplates) {
    return
  }
  console.log({ unparsedTemplates})

  const templates = JSON.parse(unparsedTemplates)
  if(!templates || templates.length === 0) {
    return
  }

  console.log({templates: templates})

  const dropdown = document.createElement("select")
  dropdown.classList.add("trix-template-dropdown")
  const blank = document.createElement("option")
  blank.value = ""
  blank.textContent = "Insert Template"
  dropdown.appendChild(blank)

  templates.forEach(template => {
    const option = document.createElement("option")
    option.value = template.id
    option.textContent = template.name
    dropdown.appendChild(option)
  })
  dropdown.onchange = (e) => {
    const value = e.target.value
    const selectedTemplate = templates.find(t => t.id == e.target.value)
    if(selectedTemplate) {
      const { html } = selectedTemplate
      if(html) {
        target.editor.insertHTML(html)
      }
    }
    dropdown.value = ""
  }


  target.toolbarElement.
    querySelector(".trix-button-group--block-tools").
    appendChild(dropdown)
})
