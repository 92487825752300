import { Controller } from "@hotwired/stimulus"
import { format, addDays, parseISO } from 'date-fns'
import currency from '../helpers/currency'
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [
    "outerWrapper",
    "row",
    "totalPercent",
    "totalMoney"
  ]
  static values = {
    total: Number
  }

  connect() {
    this.recalculate()
    $(this.outerWrapperTarget).on('cocoon:after-insert', () => {
      this.recalculate()
    })
  }

  disconnect() {
    $(this.outerWrapperTarget).off('cocoon:after-insert')
  }

  recalculate() {
    let totalPercent = 0
    let totalMoney = currency(0)

    this.rowTargets.forEach(row => {
      const selectedCalculationValue = row.querySelector('.calculation-date').value
      const selectedCalculationDate = row.querySelector('.calculation-date').selectedOptions[0].getAttribute("data-date")
      const numberDays = parseInt(row.querySelector('.number-days').value)
      const percent = parseFloat(row.querySelector('.percent').value)

      if(!selectedCalculationValue) {
        hideTarget(row.querySelector('.days-wrapper'))
        showTarget(row.querySelector('.manual-date-wrapper'))
        row.querySelector('.calculated-date').innerText = ""
      }
      else if(selectedCalculationDate && numberDays && !isNaN(numberDays)) {
        const projectedDate = addDays(parseISO(selectedCalculationDate), numberDays)
        row.querySelector('.calculated-date').innerText = format(projectedDate, "MM/dd/yyyy")

        showTarget(row.querySelector('.days-wrapper'))
        hideTarget(row.querySelector('.manual-date-wrapper'))
      }
      else {
        row.querySelector('.calculated-date').innerText = ""

        showTarget(row.querySelector('.days-wrapper'))
        hideTarget(row.querySelector('.manual-date-wrapper'))
      }

      if(isNaN(percent)) {
        row.querySelector('.calculated-amount').innerText = ""
      }
      else {
        const projectedAmount = currency(this.totalValue).multiply(percent / 100.0)
        totalMoney = totalMoney.add(projectedAmount)
        row.querySelector('.calculated-amount').innerText = projectedAmount.format()
      }

      if(!isNaN(percent)) {
        totalPercent += percent
      }
    })

    this.totalPercentTarget.innerText = `${totalPercent}%`
    this.totalMoneyTarget.innerText = totalMoney.format()
  }
}
