import React from "react"
import { Button } from "react-bootstrap"

const ModalButtons = () => (
  <>
    <Button
      variant="outline-secondary"
      size="sm"
      data-bs-toggle="modal"
      data-bs-target="#columnModal"
      className="me-1"
    >
      <i className="bi bi-columns"> </i>
      Columns
    </Button>
    <Button
      variant="outline-secondary"
      size="sm"
      data-bs-toggle="modal"
      data-bs-target="#filterModal"
    >
      <i className="bi bi-filter"> </i>
      Filter
    </Button>
  </>
)

export default ModalButtons
