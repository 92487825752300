import React, { useState, useEffect, useContext, useReducer } from "react";
import Editor from "./components/Editor";
import SaveReport from './components/SaveReport'
import ModalButtons from './components/ModalButtons'
import RefreshButton from "./components/RefreshButton";
import deserializeData from "./util/deserializeData";
import client from '../../helpers/client'
import applyChangesToData from "./util/applyChangesToData";
import changeWithSuccessStatus from './util/changeWithSuccessStatus'
import changeWithRefreshingStatus from './util/changeWithRefreshingStatus'
import changeWithErrorStatus from './util/changeWithErrorStatus'
import extractErrorFromResponse from './util/extractErrorFromResponse'
import pathForChange from './util/pathForChange'
import serializedValueForChange from './util/serializedValueForChange'
import useWindowLocation from './util/useWindowLocation'
import reorderArray from './util/reorderArray'
import EditorContext from './contexts/EditorContext'
import ExportButton from './components/ExportButton'
import SearchField from './components/SearchField'
import "./style/index.scss"


const DevelopmentProductBulkEditor = ({ baseUrl }) => {
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const [location, setLocation] = useWindowLocation()

  useEffect(() => {
    refreshData()
  }, [location])



  async function refreshData() {
    setLoading(true)

    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    }
    const response = await client.get(location, { headers })
    const { rows, columns, queryArgsJson } = response.data.data
    const { pagination } = response.data

    const deserializedData = deserializeData({ reportColumns: columns, reportRows: rows, pagination, queryArgsJson })
    setData(deserializedData)
    setReady(true)
    setLoading(false)
  }

  async function handleCellChanges(changes) {
    // Set all cells as refreshing
    for(const change of changes) {
      const refreshingChange = changeWithRefreshingStatus({ change })
      await setData(prevData => applyChangesToData([refreshingChange], prevData));
    }

    // Send changes to server
    for(const change of changes) {
      const changeWithStatus = await sendChangeToServer(change)
      await setData(prevData => applyChangesToData([changeWithStatus], prevData));
    } 
  }

  async function sendChangeToServer(change) {
    const path = pathForChange(change)
    const submittedData = serializedValueForChange(change)

    try {
      const response = await client.put(path, submittedData)
      return changeWithSuccessStatus({ change })
    }
    catch(e) {
      if(!e.response) throw e

      const error = extractErrorFromResponse(e.response)
      return changeWithErrorStatus({ change, error })
    }
  }

  async function handleColumnsReorder(targetColumnId, columnIds) {
    const newUrl = new URL(location)

    const currentSortedColumnIds = newUrl.searchParams.get("column_sort_by") ? newUrl.searchParams.get("column_sort_by").split(",") : []
    const allColumnIds = data.columns.map(column => column.columnId)

    const sortedColumnIds = [
      ...currentSortedColumnIds,
      ...allColumnIds.filter(columnId => !currentSortedColumnIds.includes(columnId))
    ]

    const to = sortedColumnIds.findIndex(columnId => columnId === targetColumnId);
    const columnIdxs = columnIds.map(columnId => sortedColumnIds.findIndex(innerColumnId => innerColumnId === columnId));
    const newSortedColumnIds = reorderArray(sortedColumnIds, columnIdxs, to);

    newUrl.searchParams.set("column_sort_by", newSortedColumnIds.join(","))
    setLocation(newUrl.toString())
  }

  async function handleColumnWidthChange(columnId, width) {
    const widthInt = parseInt(width)

    const newUrl = new URL(location)
    const existingColumnWidths = newUrl.searchParams.getAll("column_widths[]") || []
    const columnsToRetain = existingColumnWidths.filter(columnWidth => {
      const [id, w] = columnWidth.split(":")
      return id !== columnId
    })

    newUrl.searchParams.delete("column_widths[]")
    columnsToRetain.forEach(columnWidth => newUrl.searchParams.append("column_widths[]", columnWidth))
    newUrl.searchParams.append("column_widths[]", `${columnId}:${widthInt}`)

    setLocation(newUrl.toString())
  }


  return (
    <div className="development-product-bulk-editor">
      <EditorContext.Provider value={{ loading }}>
        <div className="row">
          <div className="col-4">
            <ModalButtons />
          </div>
          <div className="col-4">
            <SearchField
              // searchText={searchText}
              // onSearchTextChange={setSearchText}
              // searchDelimiter={searchDelimiter}
              // onSearchDelimiterChange={setSearchDelimiter}
            />
          </div>
          <div className="col-4 text-end">
            <RefreshButton loading={loading} onClick={refreshData} />
            <SaveReport client={client} />
          </div>
        </div>
        { ready ?
          <Editor
            data={data}
            handleCellChanges={handleCellChanges}
            handleColumnsReorder={handleColumnsReorder}
            handleColumnWidthChange={handleColumnWidthChange}
            onRefresh={refreshData}
            className={`editor ${loading ? "loading" : ""}`}
          />
          :
          <div>Loading...</div>
        }
      </EditorContext.Provider>
      <div className="text-end">
        <ExportButton client={client} queryArgsJson={data.queryArgsJson} />
      </div>
    </div>
  )
}

export default DevelopmentProductBulkEditor
