import currency from 'currency.js'

const defaultOptions = {
  precision: 3
}

function currencyWithOptions(value, options) {
  return currency(value, {...options, ...defaultOptions})
}

export default currencyWithOptions
