import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.path = this.element.getAttribute('data-path')
    this.checkStatus()
  }

  checkStatus = () => {
    fetch(this.path)
      .then(response => response.json())
      .then(data => {
        const { status, path } = data
        console.log({ status, path})
        switch(status) {
          case "running":
            setTimeout(() => this.checkStatus(), 1000)
            break;
          case "failed":
            window.location.reload()
            break;
          case "complete":
            window.location.href = path
            break;
          default:
            throw new Error(`Invalid status ${status}`)
        }
      })
      .catch(e => {
        console.warn(e)
        setTimeout(() => checkStatus(), 8000)
      })
  }
}

