import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["hide", "show"]

  connect() {
    hideTarget(this.showTargets)
  }

  activate(e) {
    if(e && e.preventDefault) {
      e.preventDefault()
    }
    hideTarget(this.hideTargets)
    showTarget(this.showTargets)
  }

}
