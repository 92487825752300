import { useState, useEffect, useCallback } from 'react'

function useWindowLocation() {
  const [location, _setLocation] = useState(window.location.toString())

  useEffect(() => {
    window.navigation.addEventListener("navigate", handleNavigate)

    return () => {
      window.navigation.removeEventListener("navigate", handleNavigate)
    }
  }, [])

  const setLocation = (newLocation) => {
    window.history.pushState({}, '', newLocation)
  }

  const handleNavigate = useCallback((event) => {
    _setLocation(event.destination.url)
  }, [])

  return [location, setLocation]
}

export default useWindowLocation
