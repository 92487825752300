import { Controller } from "stimulus"
import currency from '../helpers/currency'

export default class extends Controller {
  static targets = ["row", "paymentAmount", "totalAmount"]

  connect() {
    this.refreshTotals()
  }

  refreshTotals() {
    const totalAmount = this.rowTargets
      .filter(rowTarget => rowTarget.querySelector('.include-payment').checked)
      .map(rowTarget => rowTarget.querySelector('.payment-amount'))
      .map(paymentAmountTarget => paymentAmountTarget.value)
      .map(amount => currency(amount))
      .reduce((a, b) => a.add(b), currency(0))

    this.totalAmountTarget.innerText = totalAmount.format()
  }
}
