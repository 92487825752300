import axios from 'axios'
import applyConverters from 'axios-case-converter'

function csrfToken() {
  const element = document.head.querySelector(`meta[name="csrf-token"]`)
  if(element) {
    return element.getAttribute("content")
  }
  else {
    return null
  }
}

export function buildApiClient() {
  return applyConverters(axios.create({
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": csrfToken(),
      sameOrigin: true
    }
  }))
}

export default applyConverters(axios.create({
  headers: {'Content-Type': 'application/json', "X-CSRF-Token": csrfToken()}
}))

