import { Controller } from "stimulus"
import { hideTarget, showTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["dropdown", "start", "end", "days"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const value = this.dropdownTarget.value

    if(value === "custom") {
      showTarget(this.startTarget)
      showTarget(this.endTarget)
      hideTarget(this.daysTarget)
    }
    else if(value === "next_n_days" || value === "last_n_days" || value === "not_in_next_n_days" || value === "not_in_last_n_days") {
      hideTarget(this.startTarget)
      hideTarget(this.endTarget)
      showTarget(this.daysTarget)
    }
    else {
      hideTarget(this.startTarget)
      hideTarget(this.endTarget)
      hideTarget(this.daysTarget)
    }
  }
}
