import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["checkbox", "vendorInvoiceAdjustmentIdField", "bulkUpdateControls"]

  connect() {
    this.update()
  }

  update() {
    const checked = this.checkboxTargets.filter(c => c.checked)
    const vendorInvoiceAdjustmentIds = checked.map(checkbox => checkbox.value)
    this.vendorInvoiceAdjustmentIdFieldTarget.value = vendorInvoiceAdjustmentIds.join(",")

    if(vendorInvoiceAdjustmentIds.length) {
      showTarget(this.bulkUpdateControlsTarget)
    }
    else {
      hideTarget(this.bulkUpdateControlsTarget)
    }
  }
}

