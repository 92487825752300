import { parseISO } from 'date-fns'

const nullCell = {
  type: "text",
  text: "",
  nonEditable: true,
  meta: null
}

function deserializeData({ reportColumns, reportRows, pagination, queryArgsJson }) {
  const columns = reportColumns.map((column) => ({
    columnId: column.columnId,
    name: column.name,
    type: column.type,
    width: column.width,
  }))

  // Note that our sort uses the columnId, not the sortBy.  However we will need
  // to look at the sortBy to determine whether it's sortable or not.
  const header = {
    rowId: "header",
    cells: reportColumns.map((column) => ({
      type: "header",
      text: column.name,
      sortBy: column.sortBy ? column.columnId : null,
      sortDirection: column.sortBy ? column.sortDirection : null,
    }))
  }

  const rows = reportRows.map((row, rowIndex) => {
    const rowId = rowIndex
    const cells = reportColumns.map((column, cellIndex) => {
      const cell = row[cellIndex]

      if(!cell) return nullCell

      return {
        ...decodeCell({ cell, column }),
        id: cell.id,
        path: cell.path,
        modelName: cell.modelName,
        updateColumn: cell.updateColumn,
        nonEditable: cell.updateColumn ? false : true,
        refreshing: false,
        meta: cell
      }
    })

    return { rowId, cells }
  })


  return { columns, header, rows, pagination, queryArgsJson }
}


function decodeCell({ cell, column }) {
  switch(column.type) {
    case "string":
      return {
        type: "text",
        text: cell.value ? `${cell.value}` : ""
      }
    case "date":
      const cellDateValue = cell.value ? parseISO(cell.value) : null
      return {
        type: "date",
        date: cellDateValue
      }
    case "currency":
      const cellCurrencyValue = cell.value ? parseFloat(cell.value.value) : null
      return {
        type: "number",
        format: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        value: cellCurrencyValue
      }
    case "image":
      return {
        type: "image",
        text: cell.value ? `${cell.value}` : ""
      }
    default:
      throw new Error("Unknown type: " + column.type)
  }
}

export default deserializeData
