import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["addSizeForm", "addSizeButton"]

  connect() {
    hideTarget(this.addSizeFormTarget)
  }

  showAddSizeForm(e) {
    e.preventDefault()
    showTarget(this.addSizeFormTarget)
    hideTarget(this.addSizeButtonTarget)
    this.addSizeFormTarget.querySelector('input[type="text"]').focus()
  }
}
