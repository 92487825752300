import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["checkbox", "shipmentIdField", "bulkUpdateControls"]

  connect() {
    this.update()
  }

  update() {
    if(this.hasShipmentIdFieldTarget) {
      const checked = this.checkboxTargets.filter(c => c.checked)
      const shipmentIds = checked.map(checkbox => checkbox.value)
      this.shipmentIdFieldTargets.forEach(target => {
        target.value = shipmentIds.join(",")
      })

      if(shipmentIds.length) {
        showTarget(this.bulkUpdateControlsTarget)
      }
      else {
        hideTarget(this.bulkUpdateControlsTarget)
      }
    }
    else {
      this.hideAllCheckboxes()
    }
  }

  hideAllCheckboxes() {
    hideTarget(this.checkboxTargets)
  }
}

