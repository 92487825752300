import { Controller } from "@hotwired/stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["item", "expand"]
  static values = {
    max: { type: Number, default: 5}
  }

  connect() {
    if(this.itemTargets.length >= this.maxValue) {
      this.itemTargets.forEach((itemTarget, index) => {
        if(index >= this.maxValue) {
          hideTarget(itemTarget)
        }
      })

      this.expandTarget.innerText = `Show ${this.itemTargets.length - this.maxValue} More`
    }
    else {
      hideTarget(this.expandTarget)
    }

  }

  expand(e) {
    e.preventDefault()
    hideTarget(this.expandTarget)
    showTarget(this.itemTargets)
  }
}
