export default function extractErrorFromResponse(response) {
  if(response.status === 422) {
    const errors = response.data.errors
    return errors.join(', ')
  }
  else if(response.status === 500) {
    return "Internal server error"
  }
  else if(response.data && response.data.message) {
    return response.data.message
  }
  else {
    return "Unknown error"
  }
}
