import React from 'react'
import { Pagination } from 'react-bootstrap'

const Paginator = ({ pagination, handlePageChange }) => {
  const { currentPage, totalPages, nextPage, prevPage, firstPage, lastPage } = pagination

  const pageMinusOne = currentPage - 1
  const pageMinusTwo = currentPage - 2
  const pageMinusThree = currentPage - 3
  const pageMinusFour = currentPage - 4
  const pagePlusOne = currentPage + 1
  const pagePlusTwo = currentPage + 2
  const pagePlusThree = currentPage + 3
  const pagePlusFour = currentPage + 4

  if(totalPages <= 1) return null

  return (
    <Pagination>
      { !firstPage && <Pagination.First onClick={() => handlePageChange(0)} /> }
      { prevPage && <Pagination.Prev onClick={() => handlePageChange(prevPage)} /> }
      { pageMinusFour > 0 && <Pagination.Item onClick={() => handlePageChange(pageMinusFour)}>{pageMinusFour}</Pagination.Item> }
      { pageMinusThree > 0 && <Pagination.Item onClick={() => handlePageChange(pageMinusThree)}>{pageMinusThree}</Pagination.Item> }
      { pageMinusTwo > 0 && <Pagination.Item onClick={() => handlePageChange(pageMinusTwo)}>{pageMinusTwo}</Pagination.Item> }
      { pageMinusOne > 0 && <Pagination.Item onClick={() => handlePageChange(pageMinusOne)}>{pageMinusOne}</Pagination.Item> }
      { currentPage && <Pagination.Item active>{currentPage}</Pagination.Item> }
      { pagePlusOne <= totalPages && <Pagination.Item onClick={() => handlePageChange(pagePlusOne)}>{pagePlusOne}</Pagination.Item> }
      { pagePlusTwo <= totalPages && <Pagination.Item onClick={() => handlePageChange(pagePlusTwo)}>{pagePlusTwo}</Pagination.Item> }
      { pagePlusThree <= totalPages && <Pagination.Item onClick={() => handlePageChange(pagePlusThree)}>{pagePlusThree}</Pagination.Item> }
      { pagePlusFour <= totalPages && <Pagination.Item onClick={() => handlePageChange(pagePlusFour)}>{pagePlusFour}</Pagination.Item> }
      { nextPage && <Pagination.Next onClick={() => handlePageChange(nextPage)} /> }
      { !lastPage && <Pagination.Last onClick={() => handlePageChange(totalPages)} /> }
    </Pagination>
  )
}

export default Paginator
