import { Controller } from "stimulus"
import currency from '../helpers/currency'
import { showTarget, hideTarget, isVisible } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["wrapper", "quantity", "amount" ]

  connect() {
    this.refreshTotals()

    $(this.wrapperTarget).on('cocoon:after-insert', () => {
      this.refreshTotals()
    })
    $(this.wrapperTarget).on('cocoon:after-remove', () => {
      this.refreshTotals()
    })
  }

  disconnect() {
    $(this.wrapperTarget).off('cocoon:after-insert')
    $(this.wrapperTarget).off('cocoon:after-remove')
  }

  refreshTotals() {
    const nestedFields = [...this.element.querySelectorAll('.nested-fields')]
      .filter(field => isVisible(field))

    const totalQuantity = nestedFields
      .map(field => field.querySelector('.item-quantity'))
      .map(field => parseFloat(field.value))
      .filter(quantity => !isNaN(quantity))
      .reduce((a, b) => a + b, 0)

    const totalAmount = nestedFields
      .map(field => field.querySelector('.total-amount'))
      .map(field => currency(field.value))
      .reduce((a, b) => a.add(b), currency(0))

    this.quantityTarget.innerText = `Total Units: ${totalQuantity}`
    this.amountTarget.innerText = `Total Amount: ${totalAmount.format()}`
  }

  updateItemAmount(e) {
    const container = e.target.closest('.expense-group-row')

    const itemAmountValue = currency(e.target.value)
    const quantity = parseFloat(container.querySelector('.item-quantity').value)
    const totalAmountValue = itemAmountValue.multiply(quantity)

    container.querySelector('.total-amount').value = totalAmountValue

    this.refreshTotals()
  }

  updateItemTotal(e) {
    const container = e.target.closest('.expense-group-row')

    const totalAmountValue = currency(e.target.value)
    const quantity = parseFloat(container.querySelector('.item-quantity').value)
    const itemAmountValue = totalAmountValue.divide(quantity)

    container.querySelector('.item-amount').value = itemAmountValue

    this.refreshTotals()
  }

  updateQuantity(e) {
    const container = e.target.closest('.expense-group-row')

    const itemAmountValue = currency(container.querySelector('.item-amount').value)
    const quantity = parseFloat(container.querySelector('.item-quantity').value)
    const totalAmountValue = itemAmountValue.multiply(quantity)
    console.log({ itemAmountValue: itemAmountValue.format(), quantity, totalAmountValue: totalAmountValue.format() })

    container.querySelector('.total-amount').value = totalAmountValue

    this.refreshTotals()
  }

  fillQuantity(e) {
    e.preventDefault()
    const fillQuantity = e.target.getAttribute("data-quantity")
    if(fillQuantity) {
      const target = e.target.closest('.expense-group-row').querySelector('.item-quantity')
      target.value = fillQuantity
      this.updateQuantity(e)
    }
  }
}
