import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

const OTHER_VALUES = ["Other", "other"]

export default class extends Controller {
  static targets = ["reason", "otherWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility(e) {
    const value = this.reasonTarget.value
    if(OTHER_VALUES.includes(value)) {
      showTarget(this.otherWrapperTarget)
    }
    else {
      hideTarget(this.otherWrapperTarget)
    }
  }
}
