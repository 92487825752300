export default function pathForChange(change) {
  const { newCell, previousCell } = change
  const { id,  modelName } = newCell

  switch(modelName) {
    case 'development_product':
      return `/development_products/${id}/update_report_data.json`
    case 'sample':
      return `/samples/${id}/update_report_data.json`
      break
    default:
      throw new Error(`Unknown modelName: ${modelName}`)
  }
}
