import React from "react"

function extractCellFromData({ data, rowIndex, cellIndex }) {
  const cell = data.rows[rowIndex].cells[cellIndex]

  const { refreshing, nonEditable, error, path } = cell
  const effectiveNonEditable = refreshing ? false : nonEditable


  const renderer = (value) => {
    if(refreshing) {
      return refreshingRenderer(value)
    }
    else if(error) {
      return errorRenderer(value, error)
    }
    else if(path) {
      return pathRenderer(value, path)
    }
    else {
      return defaultRenderer(value)
    }
  }

  return {
    ...cell,
    renderer,
    nonEditable: effectiveNonEditable
  }
}

const defaultRenderer = (value) => value

const errorRenderer = (value, error) => (
  <div className="error-cell">
    <div>{ value }&nbsp;</div>
    <div className="error-message">{ error }</div>
  </div>
)

const refreshingRenderer = (value) => (
  <div className="refreshing-cell">
    { value }
    <div className="spinner-border spinner-border-sm ms-1 text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

const pathRenderer = (value, path) => (
  <div className="path-cell">
    <a href={ path } target="_blank" rel="noreferrer" className="text-dark">
      { value }
    </a>
    <a href={ path } target="_blank" rel="noreferrer" className="text-dark ms-2">
      <i className="bi bi-box-arrow-up-right"></i>
    </a>
  </div>
)

export default extractCellFromData
