import { Autocomplete } from 'stimulus-autocomplete'

export default class extends Autocomplete {
  static targets = ["submit"]
  static values = {
    identifier: String,
    link: String,
    extraParamsSelector: String
  }
  
  connect() {
    super.connect()
    document.addEventListener("autocomplete.change", this.autocomplete.bind(this))
  }

  buildURL(query) {

    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append("q", query)

    if(this.hasExtraParamsSelectorValue) {
      const targets = document.querySelectorAll(this.extraParamsSelectorValue)
      targets.forEach(target => {
        const name = target.getAttribute("data-extra-param-name")
        const value = target.value
        params.append(name, value)
      })
    }

    url.search = params.toString()

    return url.toString()
  }

  autocomplete(event) {
    // Handle multiple autocompletes on a single page
    if(event.target.id != this.identifierValue) {
      return
    }

    if(this.linkValue) {
      // Treat as a link to follow
      const url = event.detail.value
      console.log(`Redirecting to ${url}`)
      window.location = url
    }
    else {
      // Autocomplete the form
      if(this.hasSubmitTarget) {
        if(this.inputTarget.value) {
          this.submitTarget.disabled = false
        }
        else {
          this.submitTarget.disabled = true
        }
      }
    }

    if(event.detail.value) {
      this.inputTarget.classList.add("is-valid")
    }
  }

  updateAutocompleteField() {
    // When the user is typing, the autocomplete value must not be set so
    // remove any active matched classes
    this.inputTarget.classList.remove("is-valid")
  }
}
