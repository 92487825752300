import { Controller } from "stimulus"
import { hideTarget, showTarget } from '../helpers/visibility'

const lineHeight = 24
const maxLines = 3

export default class extends Controller {
  static targets = ["content", "showMore"]

  connect() {

    this.contentTarget.style.maxHeight = 'none'

    const numberOfLines = Math.floor(this.contentTarget.offsetHeight / lineHeight)

    if(numberOfLines > maxLines + 1) {
      this.contentTarget.style.maxHeight = `${lineHeight * maxLines}px`
      this.contentTarget.style.overflow = 'hidden'
      showTarget(this.showMoreTarget)
    }
    else {
      hideTarget(this.showMoreTarget)
    }
  }

  showMore(e) {
    e.preventDefault()

    this.contentTarget.style.maxHeight = 'none'
    hideTarget(this.showMoreTarget)
  }
}

