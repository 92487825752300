import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = ["addPriceForm"]

  connect() {
    console.log('here!!')
    hideTarget(this.addPriceFormTarget)
  }

  showAddPriceForm(e) {
    e.preventDefault()
    showTarget(this.addPriceFormTarget)
    this.addPriceFormTarget.querySelector('input[type="text"]').focus()
  }
}
