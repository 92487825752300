import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../helpers/visibility'

export default class extends Controller {
  static targets = [ "allDivisionsCheckbox", "divisions" ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.allDivisionsCheckboxTarget.checked) {
      hideTarget(this.divisionsTarget)
    }
    else {
      showTarget(this.divisionsTarget)
    }
  }
}
