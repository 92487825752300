import { Controller } from "stimulus"
import currency from '../helpers/currency'
import { showTarget, hideTarget } from '../helpers/visibility'

const ACTIVE_CLASS = 'btn-primary'
const INACTIVE_CLASS = 'btn-light'

export default class extends Controller {
  static targets = ["compact", "compactAdditional", "expanded", "compactBtn", "compactAdditionalBtn", "expandedBtn"]

  connect() {
    if(this.hasCompactTarget) {
      this.setCompact()
    }
    else if(this.hasCompactAdditionalTarget) {
      this.setCompactAdditional()
    }
    else if(this.hasExpandedTarget) {
      this.setExpanded()
    }
  }

  setCompact() {
    showTarget(this.compactTarget)
    this.compactBtnTarget.classList.add(ACTIVE_CLASS)
    this.compactBtnTarget.classList.remove(INACTIVE_CLASS)

    if(this.hasCompactAdditionalTarget) {
      hideTarget(this.compactAdditionalTarget)
      this.compactAdditionalBtnTarget.classList.remove(ACTIVE_CLASS)
      this.compactAdditionalBtnTarget.classList.add(INACTIVE_CLASS)
    }

    if(this.hasExpandedTarget) {
      hideTarget(this.expandedTarget)
      this.expandedBtnTarget.classList.remove(ACTIVE_CLASS)
      this.expandedBtnTarget.classList.add(INACTIVE_CLASS)
    }
  }

  setCompactAdditional() {
    showTarget(this.compactAdditionalTarget)
    this.compactAdditionalBtnTarget.classList.add(ACTIVE_CLASS)
    this.compactAdditionalBtnTarget.classList.remove(INACTIVE_CLASS)

    if(this.hasCompactTarget) {
      hideTarget(this.compactTarget)
      this.compactBtnTarget.classList.remove(ACTIVE_CLASS)
      this.compactBtnTarget.classList.add(INACTIVE_CLASS)
    }

    if(this.hasExpandedTarget) {
      hideTarget(this.expandedTarget)
      this.expandedBtnTarget.classList.remove(ACTIVE_CLASS)
      this.expandedBtnTarget.classList.add(INACTIVE_CLASS)
    }
  }

  setExpanded() {
    if(this.hasCompactTarget) {
      hideTarget(this.compactTarget)
      this.compactBtnTarget.classList.remove(ACTIVE_CLASS)
      this.compactBtnTarget.classList.add(INACTIVE_CLASS)
    }

    if(this.hasCompactAdditionalTarget) {
      hideTarget(this.compactAdditionalTarget)
      this.compactAdditionalBtnTarget.classList.remove(ACTIVE_CLASS)
      this.compactAdditionalBtnTarget.classList.add(INACTIVE_CLASS)
    }

    showTarget(this.expandedTarget)
    this.expandedBtnTarget.classList.add(ACTIVE_CLASS)
    this.expandedBtnTarget.classList.remove(INACTIVE_CLASS)
  }
}
