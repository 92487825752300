import constants from '../constants'

function extractColumnsFromData(data) {
  return data.columns.map((column) => ({
    columnId: column.columnId,
    width: column.width || constants.DEFAULT_COLUMN_WIDTH,
    resizable: true,
    reorderable: true,
    name: column.name,
    type: column.type
  }))
}

export default extractColumnsFromData
