import React from 'react';

const ExportButton = ({ client, queryArgsJson }) => {
  async function handleExport(e) {
    e.preventDefault();
    console.log({ queryArgsJson })

    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    }

    const response = await client.post('/report_downloads', { query: JSON.parse(queryArgsJson) }, { headers })
    const pendingJobPath = response.data.pendingJobPath
    window.location = pendingJobPath
  }

  return (
    <a href="#" className="subtle-edit" onClick={handleExport} role="button">
      <i className="bi bi-download"></i>
      { " " }
      Export
    </a>
  )
}

export default ExportButton;
