export default function changeWithRefreshingStatus({ change }) {
  return {
    ...change,
    newCell: {
      ...change.newCell,
      refreshing: true,
    }
  }
}

