import React from "react";
import { Chart } from "react-google-charts";
import { parseISO } from 'date-fns'

const DevelopmentProductGantt = ({ title, data }) => {
  const [columns, ...rows] = data

  const processedRows = rows.map(row => {
    const [taskId, taskName, start, end, duration, percentComplete, dependencies] = row

    const parsedStart = start ? parseISO(start) : null
    const parsedEnd = end ? parseISO(end) : null

    return [taskId, taskName, parsedStart, parsedEnd, duration, percentComplete, dependencies]
  })

  const processedData = [columns, ...processedRows]
  return (
  <>
    <h4>{ title }</h4>
    <Chart
      chartType="Gantt"
      width="100%"
      height={500}
      data={processedData}
    />
  </>
  )
}

export default DevelopmentProductGantt
