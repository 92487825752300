$(document).on('turbo:frame-load', function() {
  console.log('turbo:frame-load')
  $('table.bootstrap-table')
  // .bootstrapTable('destroy')
    .bootstrapTable({
      // showFullscreen: true,
      // stickyHeader: true,
      // fixedColumns: true,
      // fixedRightNumber: 1
    })
})
$(document).on('turbo:load', function() {
  console.log('turbo:load')
  $('table.bootstrap-table')
  // .bootstrapTable('destroy')
    .bootstrapTable({
      // showFullscreen: true,
      // stickyHeader: true,
      // fixedColumns: true,
      // fixedRightNumber: 1
    })
})

$(document).on("turbo:before-cache", function() {

  $('table.bootstrap-table').bootstrapTable('destroy');

});
